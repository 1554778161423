import style from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { InputSwitch } from '../../../../theme/component/input-switch';
import SwitchFilterOption from "../../../../theme/component/input-switch/model/switch-filter-options";
import { FreePlan, BusinessYearlyPlan, BusinessMonthlyPlan, InfluencerYearlyPlan, InfluencerMonthlyPlan } from './componets/index.componet';
import {useTranslation} from "react-i18next";


const businessOption: SwitchFilterOption = {
    label: 'Monthly',
    value: 'monthly',
    background: 'rgb(22, 22, 22)'
}

const influencerOption: SwitchFilterOption = {
    label: 'Yearly',
    value: 'yearly',
    background: 'rgb(22, 22, 22)'
}

function BlackFridaySubscription() {
    const [yearly, setYearly] = useState(false);
    const [activePlans, setActivePlans] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {

        let plans = [];
        plans.push(<FreePlan key="freePlan" />);
        if (yearly) {
            plans.push(<InfluencerYearlyPlan key="influencerYearly" />);
            plans.push(<BusinessYearlyPlan key="businessYearly" />);
        } else {
            plans.push(<InfluencerMonthlyPlan key="influencerMonthly" />);
            plans.push(<BusinessMonthlyPlan key="businessMonthly" />);
        }
        setActivePlans(plans);
    }, [yearly]);


    return <section className={style.container} id='pricing'>
        <div className={style.header}>{t('subscription_section_bf_title')}</div>
        <span className={style.switch}>
            <InputSwitch
                option1={businessOption}
                option2={influencerOption}
                onSwitch={(selectedOption) => setYearly(selectedOption.value === 'yearly')}
            />
        </span>
        <div className={style.promotion}>{t('subscription_section_subtitle')}</div>
        <div className={style.plansGrid}>
            {activePlans}
        </div>
    </section>
}

// @ts-ignore
export default BlackFridaySubscription;