import React from 'react';
import style from './index.module.scss';
import Support from './component/support';
import FooterSection from './component/footer';
import NewsletterSection from './component/newsletter';
import JoinSection from './component/join';
import  {Navigation} from './component/nvigation'
import LanguageSelector from "../../components/common/language-selection/index.component";
import {Testimonials} from "./component/testimonials";
import {KnownFrom} from "./component/known-from";
import {Benefits2} from "./component/benefits2";
import {HeroSection2} from "./component/hero2";
import {IntroductionVideo} from "./component/introduction-video";
import BlackFridaySubscription from "./component/black-friday-subscription";

export default function Landing() {
    return <div className={style.landing}>
        <LanguageSelector />
        <Navigation />
        <HeroSection2 />
        {/*<OverviewSection />*/}
        {/*<ShowBusinessProvider>*/}
        {/*</ShowBusinessProvider >*/}
        <IntroductionVideo />
        <Benefits2 />
        <section className={style.container}>
            <BlackFridaySubscription />
            <JoinSection />
        </section>
            <KnownFrom/>
        <section className={style.container}>
            <Testimonials/>
            <Support />
            {/*<BlogSection />*/}
            <NewsletterSection />
            <FooterSection />
        </section>
    </div>

}