import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../index.module.scss';
import { useTranslation } from 'react-i18next';
import DonutIcon from "../../../../../theme/resource/reactIcons/donutIcon";

interface SubscriptionTileProps {
    planId?: string;
    title: string;
    monthly?: boolean
    price: string;
    benefits: string[];
    action: string;
    onBuy?: (planId: string) => void;
    navigateTo?: string;
    priceOld?: string;
    discount?: string;
}

const FreeTile: React.FC<SubscriptionTileProps> = ({ title, price, benefits, action, navigateTo }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAction = () => {
        if (navigateTo) {
            navigate(navigateTo);
        }
    };

    return (
        <div className={style.subscriptionTile}>
            {/*<div className={style.promoBanner}>*/}
            {/*    /!*<div className={style.triangle}></div>*!/*/}
            {/*    /!*<span>{t('subscription.freeTile.promoBanner')}</span>*!/*/}
            {/*</div>*/}
            <h3 className={style.subscriptionTileTitle}>{t(title).toUpperCase()}</h3>
            <p className={style.price}>{price}</p>
            <ul className={style.benefits}>
                {benefits.map((benefit: string, index: number) => (
                    <li key={index}>
                        <span className={style.donut}><DonutIcon size={20} color={'black'}/></span>
                        {t(benefit)}
                    </li>
                ))}
            </ul>
            <div className={style.action} onClick={handleAction}>{t(action)}</div>
        </div>
    );
};

const SubscriptionTile: React.FC<SubscriptionTileProps> = ({ planId, onBuy, title, price, benefits, action, navigateTo, monthly, priceOld, discount }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAction = () => {
        if (navigateTo) {
            navigate(navigateTo);
        } else if (onBuy) {
            onBuy(planId);
        }
    };

    return (
        <div className={style.premiumSubscriptionTile}>
            <h3 className={style.premiumSubscriptionTileTitle}>{t(title).toUpperCase()}</h3>
            <p className={style.premiumPrice}>
                <span className={style.priceOld}>{priceOld}</span>
                {price}
            </p>
            {!monthly && <p className={style.price_disclaimer}>{t("subscription.price_disclaimer")}</p>}
            <span className={style.discount}>
                {discount} {t('discount')}
            </span>
            <ul className={style.premiumBenefits}>
                {benefits.map((benefit: string, index: number) => (
                        <li key={index}>
                            <span className={style.donut}><DonutIcon size={20} color={'#EDC375'}/></span>
                            {t(benefit)}
                        </li>

                ))}
            </ul>
            <div className={style.premiumAction} onClick={handleAction}>{t(action)}</div>
        </div>
    );
};

export const InfluencerMonthlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-7YR91116UY077744PMWYC73I'}
        title="subscription.influencer.title"
        price="8,70€ / mo"
        priceOld="10,90€"
        discount="20%"
        monthly={true}
        benefits={[
            'subscription.influencer.benefit1',
            'subscription.influencer.benefit2',
            'subscription.influencer.benefit3',
            'subscription.influencer.benefit4',
            'subscription.influencer.benefit5',
            'subscription.influencer.benefit6',
        ]}
        action="subscription.influencer.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const InfluencerYearlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-8W568297R08956525MW2CQDI'}
        title="subscription.influencer.title"
        price="7,90€ / mo"
        priceOld="12,90€"
        discount="20%"
        monthly={false}
        benefits={[
            'subscription.influencer.benefit1',
            'subscription.influencer.benefit2',
            'subscription.influencer.benefit3',
            'subscription.influencer.benefit4',
            'subscription.influencer.benefit5',
            'subscription.influencer.benefit6',
        ]}
        action="subscription.influencer.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const BusinessMonthlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-63340961HC971183YMWYC55A'}
        title="subscription.business.title"
        price="9,90€ / mo"
        priceOld="12,90€"
        discount="23%"
        monthly={true}
        benefits={[
            'subscription.business.benefit1',
            'subscription.business.benefit2',
            'subscription.business.benefit3',
            'subscription.business.benefit4'
        ]}
        action="subscription.business.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const BusinessYearlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-1AY65338S2323582HMWYC5AQ'}
        title="subscription.business.title"
        price="8,60€ / mo"
        priceOld="10,75€"
        discount="20%"
        monthly={false}
        benefits={[
            'subscription.business.benefit1',
            'subscription.business.benefit2',
            'subscription.business.benefit3',
            'subscription.business.benefit4'
        ]}
        action="subscription.business.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const FreePlan = () => (
    <FreeTile
        title="subscription.freePlan.title"
        price="0€"
        benefits={[
            'subscription.freePlan.benefit1',
            'subscription.freePlan.benefit2',
            'subscription.freePlan.benefit3',
        ]}
        action="subscription.freePlan.action"
        navigateTo="/join"
    />
);
