import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import InputButton from '../../theme/component/input-button'
import InputText from '../../theme/component/input-text/input-text.component'
import { AccountService } from '../../service/account/account.service'

import style from './index.module.scss'
import sessionService from '../../service/session/session.service'
import useQuery from '../../theme/hooks/use-query.component'
import { InlineToastType } from '../../theme/component/inline-notification/model/inline-notification-type.model'
import InlineToast from '../../theme/component/inline-notification'
import SessionDetails from '../../service/session/model/session-details'
import { ThirdPartyAuthorizationService } from "../../service/third-party-authorization/third-party-authorization.service"
import { useTranslation } from 'react-i18next'

type LoginProps = {
    onLogin: (loggedIn: boolean) => void
}

export default function Login({ onLogin }: LoginProps) {
    const { t } = useTranslation()
    const urlExtractedQuery = useQuery('return_to', '/explore')
    const navigate = useNavigate()

    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [disableLogin, setDisableLogin] = useState<boolean>(false)

    const [showToast, setShowToast] = useState<boolean>(false)
    const [toastMessage, setToastMessage] = useState<string>('')
    const [toastType, setToastType] = useState<InlineToastType>(null)

    useEffect(() => {
        if (sessionService.getSessionDetails().authenticated)
            navigate(urlExtractedQuery)
    }, [])

    return <span className={style.container}>

        <span className={style.image}>
            <h1 className={style.header}>{t('login.login')}</h1>
            <span className={style.mike_influence}/>
        </span>

        <div className={style.input_area}>
            <div className='text-center max-width'>
                <div className='mb2 mt1'>

                    <InlineToast show={showToast} message={toastMessage} type={toastType} />
                </div>
                <div className='mb2'>
                    <InputText placeholder={t('login.mail_or_username')} onChange={setLogin} />
                </div>
                <div className='mb3'>
                    <InputText placeholder={t('login.password')} isPassword={true} onChange={setPassword} onEnter={loginHandler} />
                </div>
                <div className='mb2'>
                    <InputButton label={t('login.sign_in')} fill={true} onClick={loginHandler} disabled={disableLogin} />
                </div>
                <div className='mb3'>
                    <div className={`${style.or} mb1`}>
                        <span className={style.label}>{t('login.or')}</span>
                    </div>
                    <div className={`${style.google_authentication_button} mb2`} onClick={() => googleSignIn('google')}>
                        <span className={style.icon}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                <path fill="#EA4335"
                                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4"
                                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05"
                                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853"
                                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                        </span>
                        <span className={style.label}>{t('login.sign_in_google')}</span>
                    </div>
                </div>
                <div className='mb2'>
                    <Link to='/forgot-password'>{t('login.forgot_password')}</Link>
                </div>
                <span className={`${style.signup} mb1`}>
                    <span className={style.label}>{t('login.new_to_influspace')}</span>
                    <Link to='/join'>{t('login.register')}</Link>
                </span>
            </div>
            {/*<GoogleLegalPrint />*/}

        </div>


    </span>

    async function googleSignIn(provider: string) {
        const url = await ThirdPartyAuthorizationService.getAuthorizeEndpoint(provider)

        console.log(url)
        window.location.href = url
    }

    function showBadLogin() {
        setShowToast(true)
        setToastMessage(t('login.bad_login'))
        setToastType(InlineToastType.Failure)
    }

    async function loginHandler() {
        setDisableLogin(true)

        const session: SessionDetails = await AccountService.signIn(login, password)

        if (session?.authenticated === true) {
            onLogin(true)
            navigate(urlExtractedQuery)
            return
        }

        showBadLogin()
        setDisableLogin(false)
    }
}
