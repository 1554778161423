import style from "./index.module.scss"
import {useTranslation} from "react-i18next";
import React, {useState} from "react";

export function IntroductionVideo() {
    const { t } = useTranslation();

    return (
        <div
            style={{
                position: "relative",
                padding: "20px",
                maxWidth: "900px",
                marginTop: "6rem",
                marginBottom: "6rem"
            }}
        >
            <div style={{
                textAlign: "center",
                marginBottom: "2rem"
            }}>
                <h1>{t("video_title")}</h1>
                <p>{t("video_subtitle")}</p>
            </div>
            <Video />
        </div>
    );


    function Video() {
        return <div
            style={{
                position: "relative",
                paddingBottom: "56.25%", // 16:9 Aspect Ratio
                height: 0,
                overflow: "hidden",
                maxWidth: "100%",
                background: "#000",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
        >
            <iframe
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: 0,
                }}
                src="https://www.youtube.com/embed/LgiqPEr_lpA?si=K3GyhEwwaLyBCvd8"
                title="Influspace Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
    }

}
