import React from 'react';
import style from './index.module.scss';
import {useTranslation} from "react-i18next";

interface SubscriptionTileProps {
    planId?: string,
    title: string;
    price: string;
    date: string;
    action: string;
    onBuy?: (planId: string) => void
}

const FreeTile: React.FC<SubscriptionTileProps> = ({ title, price, date, action }) => {
    const { t, i18n } = useTranslation();

    return <div className={style.subscriptionTile}>
        <div className={style.promoBanner}>
            <div className={style.triangle}></div>
            <span>{t('payment_plans_trial_title')}</span>
        </div>
        <h3 className={style.subscriptionTileTitle}>{title.toLocaleUpperCase()}</h3>
        <p className={style.price}>{price}</p>
        <div className={style.action}> {action}</div>
    </div>
}

const SubscriptionTile: React.FC<SubscriptionTileProps> = ({ planId, onBuy, title, price, date, action }) => {
    const { t, i18n } = useTranslation();

    return <div className={style.premiumSubscriptionTile}>
        <h3 className={style.premiumSubscriptionTileTitle}>{title.toUpperCase()}</h3>
        <p className={style.premiumPrice}>{price}</p>
        <p className={style.timeFrame}>{date}</p>
        <div className={date === t('payment_plans_yearly') ? style.premiumAction : style.premiumActionMontly} onClick={() => onBuy(planId)}>{action}</div>
    </div>
}

export const PremiumBenefitsSection = () => {
    const { t, i18n } = useTranslation();

    return <div className={style.benefitSection}>
        <ul className={style.benefits}>
            <li className={style.earlyAccess}>{t('payment_plans_benefit_early_offers')}</li>
            <li className={style.premiumOffers}>{t('payment_plans_benefit_premium_only_offers')}</li>
            <li className={style.profileBadge}>{t('payment_plans_benefit_profile_badge')}</li>
            <li className={style.higherVisibility}>{t('payment_plans_benefit_higher_profile_ranking')}</li>
            <li className={style.betterOffer}>{t('payment_plans_benefit_higher_offer_ranking')}</li>
            <li className={style.priority}>{t('payment_plans_benefit_prioritised_direct_messages')}</li>
        </ul>
    </div>
};

export const BrandAccessBenefitsSection = () => {
    const { t, i18n } = useTranslation();

    return <div className={style.benefitSection}>
        <ul className={style.benefits}>
            <li className={style.easilyConnect}>{t('payment_plans_connect_with_influencer')}</li>
            <li className={style.premiumOffers}>{t('payment_plans_reveive_applications_from_influencer')}</li>
            <li className={style.higherVisibility}>{t('payment_plans_find_long_term_ambassadors')}</li>
            <li className={style.safeTime}>{t('payment_plans_safe_time')}</li>
        </ul>
    </div>
};

export const InfluencerMonthlyPlan = ({ onBuy, planId }: { planId: string, onBuy?: (plandId: string) => void }) => {
    const { t, i18n } = useTranslation();

    return <SubscriptionTile
            planId={planId}
            title={t('payment_plans_premium_monthly_influencer')}
            price={t('payment_plans_premium_monthly_price_influencer_black_friday')}
            date={t('payment_plans_monthly')}
            action={t('payment_plans_call_to_action')}
            onBuy={() => onBuy(planId)}
    />
};

export const InfluencerYearlyPlan = ({ onBuy, planId }: { planId: string, onBuy?: (plandId: string) => void }) => {
    const {t, i18n} = useTranslation();

    return <SubscriptionTile
        planId={planId}
        title={t('payment_plans_premium_yearly_influencer')}
        price={t('payment_plans_premium_yearly_price_influencer_black_friday')}
        date={t('payment_plans_yearly')}
        action={t('payment_plans_call_to_action')}
        onBuy={() => onBuy(planId)}
    />
};

export const BrandMonthlyPlan = ({ onBuy, planId }: { planId: string, onBuy?: (plandId: string) => void }) => {
    const {t, i18n} = useTranslation();

    return <SubscriptionTile
        planId={planId}
        title={t('payment_plans_premium_monthly_brand')}
        price={t('payment_plans_premium_monthly_price_brand_black_friday')}
        date={t('payment_plans_monthly')}
        action={t('payment_plans_call_to_action')}
        onBuy={() => onBuy(planId)}
    />
};

export const BrandYearlyPlan = ({ onBuy, planId }: { planId: string, onBuy?: (plandId: string) => void }) => {
    const {t, i18n} = useTranslation();

    return <SubscriptionTile
        planId={planId}
        title="BRAND ACCESS YEARLY "
        price={t('payment_plans_premium_yearly_price_brand_black_friday')}
        date={t('payment_plans_yearly')}
        action={t('payment_plans_call_to_action')}
        onBuy={() => onBuy(planId)}
    />
};

export const FreePlan = () => {
    const {t, i18n} = useTranslation();

    return <FreeTile
        title={t('payment_plans_free_title')}
        price={t('payment_plans_free_price')}
        date={t('payment_plans_free')}
        action={t('payment_plans_call_to_action')}
        onBuy={() => {
        }}
    />
};